
.advert {
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.1);
    background-color: #fff;
    color: #172b4d;
}

.advert__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.advert__body {
    overflow: hidden;
}

.advert__type h3 {
    font-weight: 500;
}

.advert__img {
    margin-top: 8px;
    width: 100%;
    height: 100px;
}

.advert__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.advert__bottom {
    margin-top: 8px;
}

.advert__link a {
    font-weight: 300;
    transition: color 0.2s;
    color: #6495ED;
}

.advert__link a:hover {
    color: #436aaf;
}

.advert__edit-btn {
    padding: 3px 4px;
    font-size: 13px;
}

.advert__del-btn {
    padding: 3px 6px;
    font-size: 13px;
    margin-left: 6px;
}

@media (max-width: 992px) {
    .advert__head {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
    }
    .advert__controls {
        margin-top: 8px;
    }
}

@media (max-width: 420px) {
    .advert__controls {
        display: flex;
        flex-direction: column;
    }
    .advert__del-btn {
        margin-top: 8px;
        margin-left: 0;
    }
}