.advert-list {
    background-color: #fff;
    color: #172b4d;
    overflow: hidden;
}

.advert-image-container {
    max-width: 100px; /* Задайте желаемую максимальную ширину изображения */
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.advert-image {
    max-width: 100%;
    height: auto;
    max-height: 100px; /* Задайте желаемую максимальную высоту изображения */
}

.control-btn {
    text-transform: uppercase;
}

.del-btn {
    margin-left: 10px;
}

.ad-link {
    font-size: 16px;
}

@media (max-width: 768px) {
    .controls-cell {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .del-btn {
        margin-top: 8px;
        margin-left: 0;
    }
}

@media (max-width: 670px) {
    .adv-img {
        width: 50px;
        height: 50px;
        margin-right: 0!important;
    }
    .ad-link {
        font-size: 15px;
    }
    .adv-list-cell {
        padding: 8px!important;
    }
    .control-btn {
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
        min-width: auto;
    }
}