.advert-grid {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 20px;
}

@media (max-width: 576px) {
    .advert-grid {
        grid-template-columns: 49% 49%;
        grid-column-gap: 2%;
    }
}