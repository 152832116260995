.content-wrapper {
    padding: 24px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.content__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.1);
    background-color: #fff;
    color: #172b4d;
}

.main-title {
    font-size: 20px;
    font-weight: 500;
}

.content__body {
    margin-top: 14px;
}

.add-btn {
    margin-right: 14px;
}

@media (max-width: 400px) {
    .content__header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .header-controls {
        margin-top: 10px;
    }
}






